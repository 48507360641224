import axios from 'axios'
import { buildQueryString } from '../helper/helper';
import AWS from 'aws-sdk';

// const token = sessionStorage.getItem("token");
const token = localStorage.getItem("token");
let user = localStorage.getItem("user");
try {
    user = JSON.parse(user)
} catch (error) {

}
const headers = { Authorization: `Bearer ${JSON.parse(token)}` };
const base_url = process.env.REACT_APP_API_URL

//leads

export const getLeads = async (filter = "", navigate) => {
    try {
        const { data } = await axios.get(`${base_url}/lead${filter}`, { headers })
        return data;
    } catch (error) {
        if (error.response) {
          console.log('Response data:', error.response.data);
          console.log('Response status:', error.response.status);
          console.log('Response headers:', error.response.headers);
          // if (navigate) {
          //     localStorage.removeItem("token");
          //     navigate("/auth/login")
          // }
        } else {
            console.log("Error message:", error.message);
        }
        return error
    }
}

export const saveLead = async (data) => {
    const result = await axios.post(`${base_url}/lead`, data, { headers });
    return result.data

}

export const updateLead = async (data, id) => {
    const result = await axios.patch(`${base_url}/lead/${id}`, data, { headers });
    return result.data
}
export const updateLeadByLeadId = async (data, id) => {
    const result = await axios.patch(`${base_url}/lead?lead_Id=${id}`, data, { headers });
    return result.data
}


export const filterLeadById = async (id) => {
    // const { data } = await axios.get(`${base_url}/lead?_id=${id}`, { headers })
    const { data } = await axios.get(`${base_url}/lead/${id}`, { headers })
    // console.log(res);
    return data
}

export const filterLeadByLeadId = async (id) => {
    // const { data } = await axios.get(`${base_url}/lead?_id=${id}`, { headers })
    const { data } = await axios.get(`${base_url}/lead?leadId=${id}`, { headers })
    // console.log(res);
    return data
}

export const deleteLeadByIds = async (data) => {
    try {
        const result = await axios.delete(`${base_url}/lead/bulk-delete`, { headers, data })
        return result
    } catch (error) {
        console.log('err', error);
        return error
    }
}

export const sendReport = async (data) => {
    try {
        const result = await axios.post(`${base_url}/lead/send-email`, data, { headers });
        return result.data
    } catch (error) {
        if (error.response) {
            console.log("Response data:", error.response.data);
            console.log("Response status:", error.response.status);
            console.log("Response headers:", error.response.headers);
        } else {
            console.log("Error message:", error.message);
        }
    }
}

export const startFollowUp = async (data) => {
    try {
        const result = await axios.post(`${base_url}/lead/start-follow-ups`, data, { headers });
        return result
    } catch (error) {
        if (error.response) {
            console.log("Response data:", error.response.data);
            console.log("Response status:", error.response.status);
            console.log("Response headers:", error.response.headers);
        } else {
            console.log("Error message:", error.message);
        }
    }
}

export const cancelFollowUp = async (data) => {
    try {
        const result = await axios.post(`${base_url}/lead/stop-follow-ups`, data, { headers });
        return result
    } catch (error) {
        if (error.response) {
            console.log("Response data:", error.response.data);
            console.log("Response status:", error.response.status);
            console.log("Response headers:", error.response.headers);
        } else {
            console.log("Error message:", error.message);
        }
    }
}



// history

export const getHistoryData = async (lead_Id) => {
    try {
        const { data } = await axios.get(`${base_url}/history?lead_Id=${lead_Id}`, { headers })
        return data
    } catch (error) {
        console.log('err', error);
        return error
    }
}

export const createHistory = async (data) => {
    try {
        const result = await axios.post(`${base_url}/history`, data, { headers });
        return result.data
    } catch (error) {
        if (error.response) {
            console.log("Response data:", error.response.data);
            console.log("Response status:", error.response.status);
            console.log("Response headers:", error.response.headers);
        } else {
            console.log("Error message:", error.message);
        }
    }
}


//users
export const userLogin = async (userData) => {
    const { data } = await axios.post(`${base_url}/user/login`, userData);
    localStorage.setItem("token", JSON.stringify(data.data.token));
    localStorage.setItem("user", JSON.stringify(data.data.user));
    return data.data
}

export const createUser = async (userData) => {
    const response = await axios.post(`${base_url}/user/signup`, userData, { headers });
    return response
}
export const updateUser = async (body, userId) => {
    try {
        const data = await axios.patch(`${base_url}/user/${userId}`, body, { headers });
        return data
    } catch (error) {
        console.log('err', error);
    }
}
export const getUserData = () => {
    return user;
}
export const getUsers = async (role) => {
    try {
        const { data } = await axios.get(`${base_url}/user?${role ? `role.name=${role}` : ""}`, { headers })
        return data
    } catch (error) {
        console.log('err', error);
        return error
    }
}

export const getUserById = async (id) => {
    try {
        const { data } = await axios.get(`${base_url}/user/${id}`, { headers })
        return data
    } catch (error) {
        console.log('err', error);
        return error
    }
}

export const deleteUsers = async (data) => {
    try {
        const result = await axios.delete(`${base_url}/user/bulk-delete`, { headers, data })
        return result
    } catch (error) {
        console.log('err', error);
        return error
    }
}


export const getRoles = async () => {
    try {
        const { data } = await axios.get(`${base_url}/role`, { headers })
        return data
    } catch (error) {
        console.log('err', error);
        return error
    }
}

export const deleteRole = async (id) => {
    try {
        const { data } = await axios.delete(`${base_url}/role/${id}`, { headers })
        return data
    } catch (error) {
        console.log('err', error);
        return error
    }
}


export const getMessages = async (subject) => {
    try {
        const { data } = await axios.get(`${base_url}/conversation?subject=${subject}`, { headers })
        console.log('data', data)
        return data
    } catch (error) {
        console.log('err', error);
        return error
    }
}



export const customerConversation = async (data) => {
    let fd = new FormData();
    fd.append('message', data.message);
    fd.append('email', data.email);
    fd.append('cc', data.cc);
    fd.append('subject', data.subject);
    fd.append('lead_Id', data.lead_Id);
    fd.append('from', data.from);
    fd.append('replyId', data.replyId);
    fd.append('name', data.name);
    data.uploadFiles.forEach((item, index) => {
        fd.append(`files[${index}][filename]`, item.name);
        fd.append(`files[${index}][path]`, item.url);
    })

    try {
        const result = await fetch(`${base_url}/emails/reply`, {
            method: 'POST',
            body: fd
        });
        return result.data

        // Handle response here
    } catch (error) {
        console.error(error);
    }
}

export const internalConversation = async (data) => {
    let fd = new FormData();
    fd.append('message', data.message);
    fd.append('email', data.email);
    fd.append('subject', data.subject);
    fd.append('lead_Id', data.lead_Id);
    fd.append('from', data.from);
    data?.uploadFiles?.forEach((item, index) => {
        fd.append(`files[${index}][filename]`, item.name);
        fd.append(`files[${index}][path]`, item.url);
    })

    try {
        const result = await fetch(`${base_url}/emails/internal-conversation`, {
            method: 'POST',
            body: fd
        });
        return result.data

        // Handle response here
    } catch (error) {
        console.error(error);
    }
}

export const getNotification = async () => {
    try {
        const { data } = await axios.get(`${base_url}/user/get-notification?userId=${user._id}`, { headers })
        return data
    } catch (error) {
        console.log('err', error);
        return error
    }
}

// notification handlers

export const getNotificationByUser = async (limit = 15) => {
    try {
        const { data } = await axios.get(`${base_url}/notification?userId=${user._id}&limit=${limit}`, { headers })
        return data
    } catch (error) {
        console.log('err', error);
        return error
    }
}

export const createNotification = async (data, files) => {
    let fd = new FormData();
    fd.append('myfile', files);
    fd.append('message', data.message);
    fd.append('email', data.email);
    fd.append('subject', data.subject);
    fd.append('lead_Id', data.lead_Id);
    fd.append('title', data.title);
    fd.append('body', data.body);
    fd.append('users', data.users);
    fd.append('recipient', data.recipient);
    fd.append('channel', data.channel);
    fd.append('customer', data.customer);
    fd.append('from', data.from);
    if (data.buffer) {
        fd.append('buffer', JSON.stringify(data.buffer));
    }

    try {
        const result = await fetch(`${base_url}/notification`, {
            method: 'POST',
            body: fd
        });
        return result.data

    } catch (error) {
        console.error(error);
    }
}



export const deleteNotification = async (nid) => {
    try {
        const { data } = await axios.patch(`${base_url}/user/remove-notification`, { uid: user._id, nid }, { headers })
        return data
    } catch (error) {
        console.log('err', error);
        return error
    }
}

export const updateNotification = async (body, id) => {
    try {
        const { data } = await axios.patch(`${base_url}/notification/${id}`, body, { headers });
        return data
    } catch (error) {
        console.log('err', error);
    }
}


// administrative

export const updateLeadConfig = async (body) => {
    try {
        const { data } = await axios.patch(`${base_url}/config`, body, { headers });
        return data
    } catch (error) {
        console.log('err', error);
    }
}

// templates 
export const getTemplates = async (filters) => {
    try {
        if (filters) {
            const { data } = await axios.get(`${base_url}/template?${filters}`, { headers })
            return data
        } else {
            const { data } = await axios.get(`${base_url}/template`, { headers })
            return data
        }
    } catch (error) {
        console.log('err', error);
        return error
    }
}

export const addTemplate = async (body) => {
    try {
        const { data } = await axios.post(`${base_url}/template`, body, { headers })
        return data
    } catch (error) {
        console.log('err', error);
        return error
    }
}

export const getTemplateByName = async (name) => {
    try {
        const { data } = await axios.get(`${base_url}/template/by-name?name=${name}`, { headers })
        return data
    } catch (error) {
        console.log('err', error);
        return error
    }
}

export const updateTemplate = async (body, templateId) => {
    try {
        const { data } = await axios.patch(`${base_url}/template/${templateId}`, body, { headers });
        return data
    } catch (error) {
        console.log('err', error);
    }
}
export const deleteTemplateById = async (templateId) => {
    try {
        const { data } = await axios.delete(`${base_url}/template/${templateId}`, { headers });
        return data
    } catch (error) {
        console.log('err', error);
    }
}

export const createInvoice = async (body) => {
    try {
        const { data } = await axios.post(`${base_url}/template/invoice`, body, { headers });
        return data
    } catch (error) {
        if (error.response) {
            console.log("Response data:", error.response.data);
            console.log("Response status:", error.response.status);
            console.log("Response headers:", error.response.headers);
        } else {
            console.log("Error message:", error.message);
        }
    }
}


// Reports 

export const getMonthlyLead = async (year = "") => {
    try {
        const { data } = await axios.get(`${base_url}/lead/monthly-count?year=${year}`, { headers })
        return data
    } catch (error) {
        console.log('err', error);
        return error
    }
}

export const getLeadCountByDays = async (data) => {
    const filterString = buildQueryString(data);

    try {
        const { data } = await axios.get(`${base_url}/lead/daily-count${filterString}`, { headers })
        return data
    } catch (error) {
        console.log('err', error);
        return error
    }
}

export const getLeadCountByStep = async (data) => {
    const filterString = buildQueryString(data);

    try {
        const { data } = await axios.get(`${base_url}/lead/step-count${filterString}`, { headers })
        return data
    } catch (error) {
        console.log('err', error);
        return error
    }
}

export const getLeadCountByFilter = async (data) => {
    const filterString = buildQueryString(data);
    try {
        const { data } = await axios.get(`${base_url}/lead/filter-count${filterString}`, { headers })
        return data
    } catch (error) {
        console.log('err', error);
        return error
    }
}
export const getLeadCountByRevenue = async (data) => {
    const filterString = buildQueryString(data);
    try {
        const { data } = await axios.get(`${base_url}/lead/revenue-count${filterString}`, { headers })
        return data
    } catch (error) {
        console.log('err', error);
        return error
    }
}

export const getLeadCountByPerson = async (data) => {
    const filterString = buildQueryString(data);
    try {
        const { data } = await axios.get(`${base_url}/lead/person-count${filterString}`, { headers })
        return data
    } catch (error) {
        console.log('err', error);
        return error
    }
}

// s3
// configure the AWS SDK with your S3 credentials
AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION,
});
const s3 = new AWS.S3();
export const uploadFileToS3 = async (file, folderName, bucket) => {
    const randomNumber = Math.floor(Math.random() * (999999999999 - 100000000000 + 1)) + 100000000000;
    const bucketName = bucket || process.env.REACT_APP_AWS_BUCKET;
    try {
        const params = {
            Bucket: bucketName,
            Key: `${folderName}/${file.name}${folderName === 'images' ? randomNumber : ""}`,
            Body: file,
            ContentType: file.type
        };

        const uploadResult = await s3.upload(params).promise();
        const fileName = uploadResult.Key;
        if (bucket) {
            return uploadResult.Location
        } else return fileName;
    } catch (error) {
        console.error('Error uploading file:', file.name, error);
        throw error;
    }
}

export const uploadBufferToS3 = async (file, folderName, bucket) => {
    const randomNumber = Math.floor(Math.random() * (999999999999 - 100000000000 + 1)) + 100000000000;
    const buffer = Buffer.from(file.bufferData);
    const params = {
        Bucket: bucket,
        Key: `${folderName}/${file.name}${randomNumber}`,
        Body: buffer,
    };
    try {
        const uploadResult = await s3.upload(params).promise();
        return uploadResult.Location
    } catch (error) {
        console.error('Error uploading file:', file.name, error);
        throw error;
    }
}

export const generatePresignedUrl = async (bucket, key) => {
    const params = {
        Bucket: bucket,
        Key: key,
        Expires: 60 * 10, // URL expires in 10 minutes
    };

    const url = await s3.getSignedUrlPromise('getObject', params);
    return url;
}
