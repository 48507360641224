export const formatWord = (word) => {
  // Replace underscores with spaces
  var formattedWord = word.replace(/_/g, ' ');

  // Split the string into words and capitalize the first letter of each word
  formattedWord = formattedWord.replace(/\b\w/g, (char) => char.toUpperCase());

  return formattedWord;
}

export const dateComparison = (date) => {
  const givenDate = new Date(date);

  // Get the current date at midnight
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  if (givenDate > today) {
    return true;
  } else {
    return false;
  }
}


export const buildQueryString = (params) => {
  let queryString = "";

  for (const key in params) {
    const value = params[key];
    if (value !== undefined && value !== "") {
      if (queryString !== "") {
        queryString += "&";
      }
      queryString += `${key}=${encodeURIComponent(value)}`;
    }
  }

  return "?" + queryString;
}