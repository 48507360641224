import { combineReducers } from 'redux';
import CustomizerReducer from './customizer/CustomizerReducer';
import chatReducer from './chats/ChatReducer';
import notesReducer from './notes/NotesReducer';
import emailReducer from './email/EmailReducer';
import userReducer from './users/UsersReducer';
import leadReducer from './leads/LeadsReducer';
import leadFilterReducer from './leadFilter/LeadFilterReducer';

const RootReducers = combineReducers({
  CustomizerReducer,
  chatReducer,
  notesReducer,
  emailReducer,
  userReducer,
  leadReducer,
  leadFilterReducer,
});

export default RootReducers;
