import React from 'react'
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { getUserById } from '../../components/api/apiClient';
import { Box, List, ListItem, ListItemText } from '@mui/material';

export default function UserDetails() {
    const { id } = useParams();
    const [user, setUser] = React.useState(null)
    useEffect(() => {
        const getUser = async (userId) => {
            const res = await getUserById(userId)
            setUser(res?.data)
        }
        if (id) {
            getUser(id)
        }
    }, [id])
    return (
        <Box>
            {
                user ? <List>
                    <ListItem disablePadding>
                        <ListItemText> Name: {user.fullName} </ListItemText>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemText> Email: {user.email} </ListItemText>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemText> Role: {user?.role?.name} </ListItemText>
                    </ListItem>
                </List> : "Loading.."
            }
        </Box>
    )
}
