import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full-layout/loadable/Loadable';
import UserDetails from '../views/users/UserDetails';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full-layout/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank-layout/BlankLayout')));
/* ***End Layouts**** */

const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Login = Loadable(lazy(() => import('../views/authentication/Login')));
const Register = Loadable(lazy(() => import('../views/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../views/authentication/ResetPassword')));


// icons
const ReactIcons = Loadable(lazy(() => import('../views/icons/ReactIcons')));


//Leads
const Leads = Loadable(lazy(() => import("../views/leads/Leads")))
const AddLeads = Loadable(lazy(() => import("../views/leads/Add")))
const ImportLeads = Loadable(lazy(() => import("../views/leads/Import")))
const LeadDetails = Loadable(lazy(() => import("../views/leads/LeadDetails")))


//Users
const Users = Loadable(lazy(() => import("../views/users/Users")))
const AddUser = Loadable(lazy(() => import("../views/users/AddUser")))
const ImportUsers = Loadable(lazy(() => import("../views/users/ImportUser")))
const Roles = Loadable(lazy(() => import("../views/users/Roles")))
const AddRole = Loadable(lazy(() => import("../views/users/AddRole")))

// Templates
const Templates = Loadable(lazy(() => import("../views/template/Template")))
const AddTemplates = Loadable(lazy(() => import("../views/template/AddTemplate")))
const ConfigTemplates = Loadable(lazy(() => import("../views/template/ConfigTemplate")))

/* ****Reports***** */
const LeadCount = Loadable(lazy(() => import("../views/report/LeadCount")))

/* ****Reports***** */
const Success = Loadable(lazy(() => import("../views/commone/Success")))


const Router = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', element: <Navigate to="/leads" /> },
      // leads 
      { path: '/leads', element: <Leads /> },
      { path: '/leads/add', element: <AddLeads /> },
      { path: '/leads/import', element: <ImportLeads /> },
      { path: '/leads/:id', element: <LeadDetails /> },
      // users 
      { path: '/users', element: <Users /> },
      { path: '/user/:id', element: <UserDetails /> },
      { path: '/users/add', element: <AddUser /> },
      { path: '/users/import', element: <ImportUsers /> },
      { path: '/users/roles', element: <Roles /> },
      { path: '/users/add-role', element: <AddRole /> },
      // templates
      { path: '/templates', element: <Templates /> },
      { path: '/add-template', element: <AddTemplates /> },
      { path: '/config-template', element: <ConfigTemplates /> },
      { path: '/react-icons', element: <ReactIcons /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
      // reports
      { path: '/reports/lead-count', element: <LeadCount /> },
      // success
      { path: '/success', element: <Success /> },


    ],
  },
  {
    path: 'auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      { path: 'reset-password', element: <ResetPassword /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default Router;
