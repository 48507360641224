import { SAVE_LEADS } from "../constants";


const INIT_STATE = {
    leads: []
};

const leadReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SAVE_LEADS:
            return {
                leads: action.leads,
            };

        default:
            return state;
    }
};


export default leadReducer;