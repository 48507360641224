import { RESET_FILTER, UPDATE_FILTER } from "../constants";

const INIT_STATE = {
    filters: '',
    status: '',
    service_type: '',
    'sales_by.id': '',
    'marketed_by.id': '',
    'createdAt[lte]': '',
    'createdAt[gte]': '',
    'follow_up.date[gt]': '',
    'follow_up.date[lt]': '',
    page: 1,
    search: '',
    isDeleted: false,
};

const leadFilterReducer = (state = INIT_STATE, action) => {

    switch (action.type) {
        case UPDATE_FILTER:
            return {
                filters: action?.payload?.filter,
                status: action?.payload?.status,
                service_type: action?.payload?.service_type,
                'sales_by.id': action.payload['sales_by.id'],
                'marketed_by.id': action?.payload['marketed_by.id'],
                'createdAt[lte]': action?.payload['createdAt[lte]'],
                'createdAt[gte]': action?.payload['createdAt[gte]'],
                'follow_up.date[gt]': action?.payload?.follow_date_gt,
                'follow_up.date[lt]': action?.payload?.follow_date_lt,
                page: action?.payload?.page,
                search: action?.payload?.search,
                isDeleted: action?.payload?.deleted || false,
                // ...state, // Keep existing state fields
                // ...action.payload // Override with the payload values
            };

        case RESET_FILTER:
            return {
                filters: '',
                status: '',
                service_type: '',
                'sales_by.id': '',
                'marketed_by.id': '',
                'createdAt[lte]': '',
                'createdAt[gte]': '',
                page: 1,
                search: '',
                isDeleted: false,
            };
        default:
            return state;
    }
};

export default leadFilterReducer;