import { LOGIN_SUCCESS, POST_LOGIN_FAILED, POST_LOGIN_REQUEST, POST_LOGIN_SUCCESS, USER_LOGOUT } from "../constants";

const INIT_STATE = {
    isLoading: false,
    user: JSON.parse(localStorage.getItem('user')),
    error: null
};

const userReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case POST_LOGIN_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case POST_LOGIN_SUCCESS:
            localStorage.setItem("user", JSON.stringify(action.user))
            sessionStorage.setItem("token", action.user.token)
            return {
                ...state,
                isLoading: false,
                user: action.user,
                error: null
            };
        case POST_LOGIN_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                user: ""
            };
        case USER_LOGOUT:
            localStorage.removeItem("user")
            return state;
        default:
            return state;
    }
};


export default userReducer;